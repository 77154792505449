import React from "react";
import Preloader from "../components/Preloader/Preloader";
import ScrollTop from "../components/ScrollTop/ScrollTop";
import Header from "../components/HumanLights/Header";
import Footer from "../components/HumanLights/Footer";

const Layout = ({ children, header = 1, footer, className, mainClassName }) => {
  return (
    <>
      <Preloader />
      <ScrollTop />

      <div className={className}>
        {/* {header === 1 && <HeaderOne />}
        {header === 2 && <HeaderTwo />} */}

        <Header />

        <main className={mainClassName}>{children}</main>
        
        <Footer />
        
        {/* {footer === 1 && <FooterOne />}
        {footer === 2 && <FooterTwo />}
        {footer === 3 && <FooterThree />} */}
      </div>
    </>
  );
};

export default Layout;
