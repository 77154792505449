import React from "react";
import Layout from "../../layouts/Layout";
import PrivacyPolicyContent from "../../components/HumanLights/PrivacyPolicyContent";

const PrivacyPolicy = () => {
  return (
    <Layout header={1} footer={3} className="" mainClassName="">
      <PrivacyPolicyContent />
    </Layout>
  );
};

export default PrivacyPolicy;
