import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="footer-area-two footer-area-three">
        <div className="container">
          <div className="footer-top-two">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="footer-content-two text-center">
                  <div className="logo">
                    <Link to="/">
                      <img src="/img/human-lights/logo.png" alt="" />
                    </Link>
                  </div>
                  <p>
                    İletişim için: <a href="mailto:info@humanlights.technology">info@humanlights.technology</a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom-two">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="copyright-text">
                  <p>© 2024, Humanlights Technology.</p>
                </div>
              </div>
              <div className="col-md-6 text-end">
                <Link to="/gizlilik-sozlesmesi">Gizlilik Sözleşmesi</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
