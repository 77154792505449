import React from "react";

const PrivacyPolicyContent = () => {
  return (
    <section className="blog-details-area pt-175 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="blog-details-wrap">
                <h2 className="title">
                  Gizlilik Politikası
                </h2>
                <p>
                  Son Güncelleme Tarihi: 03.12.2024
                  <br />
                  <br />
                  Humanlight Teknoloji ve Pazarlama Hizmetleri A.Ş. (Humanlights) olarak, gizliliğinize ve kişisel verilerinizin korunmasına büyük önem veriyoruz. Bu Gizlilik Sözleşmesi, Pekibu uygulamasını kullandığınızda kişisel bilgilerinizin nasıl toplandığını, işlendiğini ve korunduğunu açıklamaktadır. Lütfen bu sözleşmeyi dikkatlice okuyun.
                  <br />
                  <br />
                  <b>1. Topladığımız Bilgiler</b>
                  <br />
                  Humanlights platformlarını kullanırken, yalnızca aşağıdaki türden bilgileri toplarız:
                  <br />
                  <b>a) Kullanıcı Tarafından Sağlanan Bilgiler:</b>
                  <br />
                  Telefon Numarası: Kayıt işlemi sırasında yalnızca telefon numaranızı talep ediyoruz.
                  <br />
                  <b>b) Uygulama İçi Hareketler:</b>
                  <br />
                  Uygulama içinde anonim olarak izlenen hareketler, platformun kullanımını geliştirmek ve kullanıcı deneyimini optimize etmek amacıyla toplanır. Bu veriler kimliğinizi belirlemek için kullanılmaz.
                  <br />
                  <br />
                  <b>2. Verilerin Kullanım Amaçları</b>
                  <br />
                  Topladığımız veriler şu amaçlarla kullanılmaktadır:
                  <br />
                  <ul>
                    <li>Platformun işlevselliğini ve güvenliğini sağlamak,</li>
                    <li>Kullanıcı deneyimini iyileştirmek ve uygulamayı optimize etmek,</li>
                    <li>İlgili yasal yükümlülüklere uymak.</li>
                  </ul>
                  <br />
                  <b>3. Verilerin Paylaşımı</b>
                  <br />
                  Kişisel bilgileriniz üçüncü taraflarla paylaşılmaz, satılmaz veya kiralanmaz. Yalnızca aşağıdaki durumlarda veriler paylaşılabilir:
                  <br />
                  Yasal bir zorunluluk söz konusuysa,
                  <br />
                  Kullanıcıların açık rızası varsa.
                  <br />
                  <br />
                  <b>4. Verilerin Saklanması</b>
                  <br />
                  Toplanan veriler, Türkiye sınırları içerisindeki güvenli sunucularımızda saklanır.
                  <br />
                  Verileriniz yalnızca yukarıdaki amaçlar doğrultusunda gerektiği süre boyunca saklanır.
                  <br />
                  <br />
                  <b>5. Güvenlik</b>
                  <br />
                  Kullanıcı bilgilerinin korunması için gerekli tüm teknik ve idari önlemler alınmaktadır. Ancak, internet üzerinden yapılan veri iletimlerinin tamamen güvenli olmadığını hatırlatırız. Bu nedenle, bilgilerinizi korumak için kendi güvenlik önlemlerinizi almanız da önemlidir.
                  <br />
                  <br />
                  <b>6. Haklarınız</b>
                  <br />
                  6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) kapsamında aşağıdaki haklara sahipsiniz:
                  <br />
                  <ul>
                    <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme,</li>
                    <li>İşlenen kişisel verileriniz hakkında bilgi talep etme,</li>
                    <li>İşleme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                    <li>Verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</li>
                    <li>KVKK’da öngörülen şartlar çerçevesinde verilerinizin silinmesini veya yok edilmesini isteme,</li>
                    <li>Bu işlemlerin, verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                    <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,</li>
                    <li>Kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme.</li>
                  </ul>
                  <br />
                  Haklarınızı kullanmak için bizimle [email adresi veya iletişim bilgisi] üzerinden iletişime geçebilirsiniz.
                  <br />
                  <br />
                  <b>7. Değişiklikler</b>
                  <br />
                  Bu Gizlilik Sözleşmesi zaman zaman güncellenebilir. Güncellemeler, uygulamada yayımlandığı tarihte yürürlüğe girer. Herhangi bir değişiklikten haberdar olmak için sözleşmeyi düzenli olarak gözden geçirmenizi öneririz.
                  <br />
                  <br />
                  <b>8. İletişim</b>
                  <br />
                  Bu Gizlilik Sözleşmesi veya kişisel verilerinizle ilgili herhangi bir sorunuz varsa, lütfen bizimle iletişime geçmekten çekinmeyin:
                  <br />
                  <br />
                  Humanlights Technology ve Pazarlama Hizmetleri A.Ş. Adres: Nispetiye Cad. Fecriebcioğlu Sok. No:3 Daire:1 E-posta: info@humanlights.technology 
                </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicyContent;
